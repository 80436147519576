//TODO: rearrange this
angular.module('sq.user.auth.events', ['sq.user.auth',
'sq.user.auth.interceptor', 'sq.user.auth.tokens'])
.factory('authEvents', AuthEventFactory);

function AuthEventFactory(userTokens, authHttpService, Auth, $rootScope, $log) {
  const authEvents = {};

  authEvents.listen = function listen() {
    $rootScope.$on('sq.user.auth.interceptor:loginRequired', () => {
      $log.debug('refreshing token because loginRequired');

      userTokens.refreshIdTokenAsync().then(() => {
        $log.debug('token refreshed');

        authHttpService.loginConfirmed();

        $log.debug('refresh confirmed');
      }, (rejection) => {
        $log.debug('refresh rejected', rejection.data);

        // Auth.logoutIfUnauth(rejection);
      });
    });

    $rootScope.$on('sq.user.auth.interceptor:forbidden', () => {
      $log.debug('received auth interceptor forbidden message, forcing logout');
      Auth.logout('Forbidden', true);
    });
  };
  
  return authEvents;
}
